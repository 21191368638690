.slick-prev:before {
  color: orange;
}
.slick-next:before {
  color: orange;
}

.icon {
  font-size: 30px;
  color:orange;
  text-shadow: 1px 1px 0 rgb(255, 255, 255), -1px 1px 0 rgb(255, 255, 255),
  -1px -1px 0 rgb(255, 255, 255), 1px -1px 0 rgb(255, 255, 255);
  transition: 0.3s ease-in;
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
}
.icon-contact {
  font-size: 20px;
  color:gray;
  transition: 0.3s ease-in;
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
}

.icon-phone {
  font-size: 20px;
  color: rgba(43,42,41,255);
  transition: 0.3s ease-in;
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
}

.icon-menu {
  font-size: 25px;
  color: rgba(0, 187, 255, 0.891);
  transition: 0.3s ease-in;
  cursor: pointer;
  display: none;
}

.error {
  font-size: 10px;
  color: red;
  position: absolute;
  left: 0;
  bottom: -10px;
}

.szukanie{
  position: absolute;
  top:0;
  left:160px;
}

.rezerwacja{
  position: absolute;
  top:80px;
  right:0;
}


.werykfikacja{
  position: absolute;
  bottom:0;
  left:160px;
}


.wnioski{
  position: absolute;
  top:80px;
  left:0;
}

.arrow-szukaj{
font-size: 30px;
color:gray;

position: absolute;
top:30px;
left:90px;
}

.arrow-rezerwacja{
font-size: 30px;
color:gray;

position: absolute;
top:30px;
right:90px;
rotate:90deg;
}

.arrow-weryfikacja{
  font-size: 30px;
  color:gray;
  
  position: absolute;
  bottom:30px;
  left:90px;
  rotate:-90deg;
  }

.arrow-wnioski{
  font-size: 30px;
  color:gray;
  
  position: absolute;
  bottom:30px;
  right:90px;
  rotate:180deg;
  }


@media (max-width: 850px) {
  .icon-menu {
    display: block;
  }

  .icon-phone {
    font-size: 16px;
  }

  .icon{
    font-size: 20px;
  }
  .icon-contact{
    font-size: 13px;
    /* display: none; */

  }
  .arrow-szukaj{

    color:rgb(255, 255, 255);
    }
    
    .arrow-rezerwacja{
    color:rgb(255, 255, 255);
    }
    
    .arrow-weryfikacja{
      color:rgb(255, 255, 255);
      }
    
    .arrow-wnioski{

      color:rgb(255, 255, 255);
      }
}

@media(max-width:500px){
  .szukanie{
    top:0;
    left:175px;
  }
  
  .rezerwacja{
    top:100px;
    right:40px;
  }
  
  
  .werykfikacja{
    bottom:0;
    left:175px;
  }
  
  
  .wnioski{
    top:100px;
    left:40px;
  }
  
  .arrow-szukaj{
  font-size: 30px;
  color:rgb(255, 255, 255);

  top:30px;
  left:90px;
  }
  
  .arrow-rezerwacja{
  font-size: 30px;
  color:rgb(255, 255, 255);
 
  top:30px;
  right:90px;
  rotate:90deg;
  }
  
  .arrow-weryfikacja{
    font-size: 30px;
    color:rgb(255, 255, 255);

    bottom:30px;
    left:90px;
    rotate:-90deg;
    }
  
  .arrow-wnioski{
    font-size: 30px;
    color:rgb(255, 255, 255);

    bottom:30px;
    right:90px;
    rotate:180deg;
    }

}